import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './index.vue';
import { getLangCode } from '@/common/getLanguage';
import { createK18n } from '@k18n/vue2-k18n';
const k18nPkg = require('@k18n/share-language/language/k18n/index.json');
const k18n = createK18n(Vue, {
  locale: getLangCode(), // 设置地区
  messages: k18nPkg, // 设置地区信息
});

const $k18n = (k18n as any).$k18n;

Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

new Vue({
  i18n: k18n,
  provide: () => {
      return { $k18n };
  },
    render: h => h(App),
}).$mount('#app');
